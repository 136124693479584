import { type App } from 'vue';
import VzButton from '@/shared/components/buttons/vz-button.vue';
import VzAvatar from '@/shared/components/vz-avatar.vue';
import VzStarRating from '@/shared/components/fields/vz-star-rating.vue';
import VzIcon from '@/shared/components/icon/vz-icon.vue';
import VzOverlay from '@/shared/components/overlay/vz-overlay.vue';
import VzSpinner from '@/shared/components/loaders/vz-spinner.vue';
import VzStepper from '@/shared/components/stepper/vz-stepper.vue';
import VzTextarea from '@/shared/components/fields/vz-textarea.vue';
import VzCheckbox from '@/shared/components/fields/vz-checkbox.vue';
import VzDataTable from '@/shared/components/tables/vz-data-table.vue';
import VzPopoverMenu from '@/shared/components/menus/vz-popover-menu.vue';
import VzErrorAlert from '@/shared/components/vz-error-alert.vue';
import VzBadge from '@shared/components/badge/vz-badge.vue';
import VzCard from '@/shared/components/vz-card.vue';
import VzSearchPanel from '@/shared/components/fields/vz-search-panel.vue';
import VzCollapse from '@/shared/components/vz-collapse.vue';
import VzCalendar from '@/shared/components/calendar/vz-calendar.vue';
import VzInput from '@/shared/components/fields/vz-input.vue';
import VzDatepicker from '@/shared/components/fields/vz-datepicker.vue';
import VzDivider from '@/shared/components/vz-divider.vue';
import VzSelect from '@/shared/components/fields/vz-select/vz-select.vue';
import VzTabSwitcher from '@/shared/components/content-switcher/vz-tab-switcher.vue';
import VzCardBadge from '@shared/components/badge/vz-card-badge.vue';
import VzInfinityScroll from '@/shared/components/infinity-scroll/vz-infinity-scroll.vue';
import VzMessenger from '@/shared/components/messenger/vz-messenger.vue';
import VzForm from '@shared/components/fields/vz-form/vz-form.vue';
import VzBubbleSelect from '@shared/components/fields/vz-select/vz-bubble-select.vue';
import VzSvgHref from '@shared/components/svg-href/svg-href.vue';
import VzImage from '@shared/components/vz-image.vue';
import VzAudio from '@shared/components/vz-audio.vue';
import VzCloseButton from '@shared/components/buttons/vz-close-button.vue';
import VzStatusBadge from '@shared/components/badge/vz-status-badge.vue';
import VzAsyncSelect from '@shared/components/fields/vz-select/vz-async-select.vue';
import VzModal from '@shared/components/overlay/vz-modal.vue';
import VzField from '@shared/components/fields/vz-field.vue';
import VzTag from '@shared/components/fields/vz-tag.vue';
import VzRichText from '@shared/components/fields/vz-rich-text.vue';
import VzCanvasBoardCropper from '@shared/components/fields/vz-canvas-board-cropper.vue';
import VzMultiCanvasBoardCropper from '@shared/components/fields/vz-multi-canvas-board-cropper.vue';
import VzSplitView from '@shared/components/vz-split-view.vue';
import VzComboDatepicker from '@shared/components/calendar/vz-combo-datepicker.vue';

const vzComponents = {
  install: (app: App<Element>) => {
    app
      .component('VzIcon', VzIcon)
      .component('VzImage', VzImage)
      .component('VzAudio', VzAudio)
      .component('VzSpinner', VzSpinner)
      .component('VzButton', VzButton)
      .component('VzAvatar', VzAvatar)
      .component('VzInput', VzInput)
      .component('VzSelect', VzSelect)
      .component('VzBubbleSelect', VzBubbleSelect)
      .component('VzCheckbox', VzCheckbox)
      .component('VzTextarea', VzTextarea)
      .component('VzStarRating', VzStarRating)
      .component('VzOverlay', VzOverlay)
      .component('VzStepper', VzStepper)
      .component('VzDataTable', VzDataTable)
      .component('VzBadge', VzBadge)
      .component('VzPopoverMenu', VzPopoverMenu)
      .component('VzErrorAlert', VzErrorAlert)
      .component('VzCard', VzCard)
      .component('VzSearchPanel', VzSearchPanel)
      .component('VzCollapse', VzCollapse)
      .component('VzCalendar', VzCalendar)
      .component('VzDatepicker', VzDatepicker)
      .component('VzDivider', VzDivider)
      .component('VzTabSwitcher', VzTabSwitcher)
      .component('VzCardBadge', VzCardBadge)
      .component('VzStatusBadge', VzStatusBadge)
      .component('VzInfinityScroll', VzInfinityScroll)
      .component('VzMessenger', VzMessenger)
      .component('VzForm', VzForm)
      .component('VzAsyncSelect', VzAsyncSelect)
      .component('VzSvgHref', VzSvgHref)
      .component('VzCloseButton', VzCloseButton)
      .component('VzModal', VzModal)
      .component('VzField', VzField)
      .component('VzTag', VzTag)
      .component('VzRichText', VzRichText)
      .component('VzCanvasBoardCropper', VzCanvasBoardCropper)
      .component('VzMultiCanvasBoardCropper', VzMultiCanvasBoardCropper)
      .component('VzSplitView', VzSplitView)
      .component('VzComboDatepicker', VzComboDatepicker);
  },
};

export { vzComponents };
