<template>
  <vz-overlay
    ref="vzOverlayRef"
    v-model:is-open="vIsOpen"
    class="vz-modal"
    v-bind="$attrs"
    :fit-content="fitContent"
    :size="size"
    :loading="loading"
    :disable-escape="disableEscape"
    :hide-close-button="hideCloseButton"
    :open-event="openEvent"
    :open-callback="openCallback"
    :close-event="closeEvent"
    :close-callback="closeCallback"
    :submit-callback="submitCallback"
    :warning-callback="warningCallback"
    @update:is-open="$emit('update:is-open', $event)"
    @close="$emit('close', $event)"
    @open="$emit('open', $event)"
  >
    <template #activator="{ toggle }">
      <slot name="activator" :toggle="toggle" />
    </template>

    <div class="vz-modal__header">
      <slot v-if="$slots['header'] || title" name="header">
        <p v-if="title" class="text-title-3">{{ $t(title) }}</p>

        <p v-if="subtitle" class="mt-2 text-body-2">{{ $t(subtitle) }}</p>
      </slot>

      <vz-error-alert class="mb-6" :errors="vzOverlayRef?.errors || errors || submitRequest?.error.value" />
    </div>

    <slot name="default" v-bind="bind" :errors="errorFields" :server-errors="vzOverlayRef?.errors || errors" />

    <div v-if="$slots['actions'] || submitCallback" class="vz-modal__actions">
      <slot v-if="$slots['actions']" name="actions" v-bind="bind" />

      <vz-button
        v-else-if="submitCallback"
        class="min-width-120"
        text="GENERAL.CANCEL"
        :disabled="submitRequest?.loading.value"
        @click="vzOverlayRef?.close"
      />

      <vz-button v-if="submitCallback" class="min-width-120" text="GENERAL.SUBMIT" :loading="submitRequest?.loading.value" @click="onSubmit" />
    </div>
  </vz-overlay>
</template>

<script setup lang="ts">
import type { VzOverlayRef } from '@shared/components/overlay/vz-overlay.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { computed, type PropType, ref } from 'vue';
import { useAsync, useServerErrorsMapper } from '@shared/composables';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';

const emit = defineEmits(['update:is-open', 'open', 'close']);

const props = defineProps({
  isOpen: { type: Boolean, default: false },
  title: { type: String as PropType<string | undefined>, default: undefined },
  subtitle: { type: String as PropType<string | undefined>, default: undefined },
  fitContent: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  loading: { type: Boolean, default: false },
  disableEscape: { type: Boolean, default: false },
  warningCallback: { type: [Boolean, Function] as PropType<boolean | ((payload?: any) => Promise<unknown>)>, default: false },
  hideCloseButton: { type: Boolean, default: false },
  openEvent: { type: String as PropType<string | undefined>, default: undefined },
  closeEvent: { type: String as PropType<string | undefined>, default: undefined },
  openCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  closeCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  submitCallback: { type: Function as PropType<() => Promise<unknown> | unknown | undefined>, default: undefined },
  size: { type: String as PropType<keyof typeof VzOverlayEnum | undefined>, default: VzOverlayEnum.SMALL },
});

const vzOverlayRef = ref<VzOverlayRef>(undefined);

const submitRequest = props.submitCallback
  ? useAsync(props.submitCallback as () => any, { successCallback: () => vzOverlayRef.value?.close(true) })
  : undefined;

const vIsOpen = computed({
  get: () => props.isOpen,
  set: (value) => emit('update:is-open', value),
});

const errorFields = useServerErrorsMapper(computed(() => vzOverlayRef.value?.errors || props.errors || null));
const bind = {
  close: () => vzOverlayRef.value?.close(),
  open: () => vzOverlayRef.value?.open(),
  errors: computed(() => vzOverlayRef.value?.errors || props.errors),
};

const onSubmit = (): void => {
  submitRequest?.call();
};

defineExpose({
  close: (isForce?: boolean) => vzOverlayRef.value?.close(isForce),
  open: () => vzOverlayRef.value?.open(),
  errors: computed(() => vzOverlayRef.value?.errors || props.errors),
});
</script>

<style lang="scss">
.vz-modal {
  > :not(:first-child):not(:last-child):not(:nth-last-child(2)) {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 1rem;
  }

  &__header {
    position: relative;
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 1rem;
    position: relative;
    height: fit-content;
    width: 100%;

    @include max-tablet-layout {
      padding-bottom: 2rem;
    }
  }

  > * {
    padding: 1rem;
  }
}
</style>
