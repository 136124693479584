import type { RouteItem } from '@/router/models/route-item';
import type { IconName } from '@shared/components/icon/icon.type';
import { computed, type ComputedRef, ref, type Ref } from 'vue';
import { GET_AUTH_MODULES, IS_LOGGED_IN } from '@/store/auth/auth.constants';
import useAuthStore from '@/views/lobby/modals/login-modal/composables/use-auth-store';
import { kebabCaseToScreamingSnakeCase } from '@/shared/helpers';
import router from '@/router';
import store from '@/store';
import { StoreNamespace } from '@/store/store-namespace';
import { findAllChildrenRoutes, getChildrenRouteNames } from '@/router/helpers/find-all-children-routes';

export const useMenuItems = (
  options: Partial<{ routeName: ComputedRef<string>; items: Ref<Array<RouteItem | string>>; authModules?: ComputedRef<Array<string> | null> }> = {}
): ComputedRef<Array<RouteItem>> => {
  const { useGetters } = useAuthStore();
  const { [GET_AUTH_MODULES]: authModulesGetter } = useGetters([GET_AUTH_MODULES]);

  return computed((): Array<RouteItem> => {
    const { routeName, items = ref(getChildrenRouteNames(routeName?.value)) } = options;

    const routeChildren = routeName?.value ? findAllChildrenRoutes(routeName.value) : router.getRoutes();
    const routes = (routeChildren || []).reduce(
      (map: Record<string, Record<string, any>>, { name, meta }) => (name ? { ...map, [name]: meta || {} } : map),
      {}
    );

    return items.value
      .map((item) => {
        const route = routeChildren.find(({ name }) => (typeof item === 'object' ? name === item.name : name === item));

        return typeof item === 'object'
          ? {
              label: (route?.meta?.title as string) || `MODULE.${kebabCaseToScreamingSnakeCase(item.name)}`,
              icon: route?.meta?.icon as IconName,
              redirect: route?.redirect,
              ...item,
            }
          : {
              name: item,
              label: (route?.meta?.title as string) || `MODULE.${kebabCaseToScreamingSnakeCase(item)}`,
              icon: route?.meta?.icon as IconName,
              redirect: route?.redirect,
            };
      })
      .filter(({ name }) => {
        // check if page available only for signed users
        if (routes[name]?.signed && !store.getters[`${StoreNamespace.AUTH_MODULE}/${IS_LOGGED_IN}`]) {
          return false;
        }

        // check if page is unprotected
        if (routes[name]?.unprotected) {
          return true;
        }

        const route = routeChildren.find((route) => route.name === name);
        const children = route?.children?.map(({ name }) => name as string) || [];

        // check if user allowed to access page
        return (
          (options.authModules?.value || authModulesGetter.value)?.includes(name) ||
          children.some((child: string) => (options.authModules?.value || authModulesGetter.value)?.includes(child))
        );
      });
  });
};
