<template>
  <div
    :class="[
      'tab-switcher',
      { 'tab-switcher--with-content': isContentExists, 'tab-switcher--flat': flat, 'tab-switcher--fit': fit, 'tab-switcher--stepper': stepper },
    ]"
  >
    <div v-if="!hideTabs" :class="['tab-switcher-header', { 'tab-switcher-header--active': $slots['header'] || title, 'sticky-top': sticky }]">
      <div v-if="title" class="text-title-3 text-center text-ellipsis">{{ $t(title) }}</div>

      <slot name="header" :index="vIndex" :tab="tabName" />

      <div v-if="isTabsShown && !isTabsHideOnSplash" class="tab-switcher-navigation">
        <div
          v-if="isOverSize && !hideBackButton"
          :class="['tab-switcher-navigation__back', { 'tab-switcher-navigation__back--disabled': !isPrevPageEnabled }]"
          @click="onPrevPage"
        >
          <vz-icon name="svg:previous" size="1rem" :aria-label="$t('GENERAL.BACK')" :color="isPrevPageEnabled ? 'currentColor' : 'mono-300'" />
        </div>

        <div class="tab-switcher-navigation__panel">
          <div class="d-flex overflow-hidden">
            <div v-if="$slots['prepend']" class="tab-switcher-navigation__panel-actions">
              <slot name="prepend" :index="vIndex" :tab="tabName" />
            </div>

            <div
              v-for="({ label, iconName, color }, index) in tabsChunk"
              :key="index"
              :class="[
                'tab-switcher-navigation__panel-tab',
                {
                  'tab-switcher-navigation__panel-tab--active': !hideActiveStyle && activeShownIndex === index,
                  'tab-switcher-navigation__panel-tab--mark': activeShownIndex === index,
                  'text-uppercase': uppercase,
                  'justify-center': +numOfShown > 1,
                },
              ]"
              :style="{ minWidth: minTabWidth, color: `var(--color-${color})` }"
              @click="onSelect(startAt + index, true)"
            >
              <vz-icon v-if="iconName" :name="iconName" size="1.25rem" />

              <span class="fill-width text-ellipsis text-center">{{ label ? $t(label.toString()) : null }}</span>
            </div>

            <template v-if="!isLastChunk && chunkSize > 1">
              <div class="tab-switcher-navigation__panel-tab max-fit-width" @click="$emit('more', { index, tabs: switcherTabs })">
                <span>...</span>
              </div>

              <div class="tab-switcher-navigation__panel-tab text-ellipsis overflow-hidden" @click="onSelect(totalTabs - 1)">
                {{ $t(lastTab.label) }}
              </div>
            </template>
          </div>

          <div v-if="$slots['append']" class="tab-switcher-navigation__panel-actions">
            <slot name="append" :index="vIndex" />
          </div>
        </div>

        <div
          v-if="isOverSize && !hideNextButton"
          :class="['tab-switcher-navigation__next', { 'tab-switcher-navigation__next--disabled': !isNextPageEnabled }]"
          @click="onNextPage"
        >
          <vz-icon name="svg:arrow-right" size="1rem" :aria-label="$t('GENERAL.NEXT')" :color="isNextPageEnabled ? 'currentColor' : 'mono-300'" />
        </div>
      </div>
    </div>

    <div v-if="isContentExists" ref="contentRef" :class="contentClass" :style="tabStyle">
      <slot v-if="$slots['splash']" name="splash" />

      <template v-else>
        <template v-if="$slots[tabName || vIndex + 1]">
          <slot :name="tabName || vIndex + 1" v-bind="$attrs" />
        </template>

        <slot v-else :index="vIndex" v-bind="$attrs" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SwitchTab } from '@/shared/components/content-switcher/tab-switcher.type';
import { computed, nextTick, type PropType, ref, useSlots, watch } from 'vue';
import { SizeUnit } from '@shared/types';
import { routeTo } from '@shared/composables';
import { useFormValidator } from '@shared/components/fields/helpers';
import { isEqual } from 'lodash';
import VzIcon from '@shared/components/icon/vz-icon.vue';

const props = defineProps({
  hideActiveStyle: { type: Boolean, default: false },
  autoActiveLast: { type: Boolean, default: false },
  hideSingleTab: { type: Boolean, default: false },
  fit: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  stepper: { type: Boolean, default: false },
  sticky: { type: Boolean, default: false },
  minTabWidth: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  title: { type: String as PropType<string | undefined>, default: undefined },
  hideTabs: { type: Boolean, default: false },
  uppercase: { type: Boolean, default: true },
  autofocus: { type: Boolean, default: true },
  index: { type: [Number, String] as PropType<string | number | undefined>, default: undefined },
  tabs: { type: [Number, Array] as PropType<Array<string | number | SwitchTab> | number>, required: true },
  numOfShown: { type: [Number, String], default: 4 },
  hideNextButton: { type: Boolean, default: false },
  hideBackButton: { type: Boolean, default: false },
  hideTabsOnSplash: { type: Boolean, default: false },
  enableEmptyState: { type: Boolean, default: true },
  hiddenIndexList: { type: Array as PropType<Array<number>>, default: () => [] },
  class: { type: [Object, Array, String] as PropType<string | Record<string, any> | Array<string | Record<string, any>>>, default: () => [] },
  beforeValidationCallback: { type: Function as PropType<(() => void | boolean) | undefined>, default: undefined },
});

const emit = defineEmits(['update:index', 'more']);
const slots = useSlots();

const startAt = ref<number>(0);
const tabIndex = ref<number>(props.index !== undefined ? +props.index : 0);
const contentRef = ref<HTMLDivElement | undefined>(undefined);

const switcherTabs = computed((): Array<SwitchTab> => {
  if (Array.isArray(props.tabs)) {
    return props.tabs.map((value) => (typeof value === 'object' ? value : { label: value.toString() })).filter((value) => !value.disabled);
  }

  return Array.from(Array(+props.tabs).keys()).map((num) => ({ label: (num + 1).toString() }));
});

const vIndex = computed({
  get: () => tabIndex.value,
  set: (value: number) => {
    tabIndex.value = value;
    emit('update:index', value);
    setCenterView();
  },
});

const validate = (isSilent?: boolean): boolean => {
  props.beforeValidationCallback?.();

  if (switcherTabs.value?.[vIndex.value]?.validate) {
    const isValid = useFormValidator(contentRef, isSilent);

    return isValid();
  }

  return true;
};

const isContentExists = computed(
  (): boolean => !!slots['content'] || !!slots['default'] || !!slots[`${tabName.value || vIndex.value + 1}`] || !!slots['splash']
);

const contentClass = computed(() => {
  return [
    'tab-switcher-content',
    `tab-switcher-content-${vIndex.value}`,
    { 'tab-switcher-content--splash': slots['splash'] },
    ...(Array.isArray(props.class) ? props.class : [props.class]),
  ];
});

const shownLimit = computed((): number => +props.numOfShown);

const isTabsShown = computed((): boolean => {
  if (props.hideSingleTab && switcherTabs.value.length <= 1) {
    return false;
  }

  return !!switcherTabs.value.length && !props.hiddenIndexList.includes(vIndex.value);
});

const isTabsHideOnSplash = computed((): boolean => !!slots['splash'] && props.hideTabsOnSplash);

const tabName = computed(() => switcherTabs.value?.[vIndex.value]?.name);
const tabStyle = computed(() => switcherTabs.value?.[vIndex.value]?.style);

const totalTabs = computed((): number => switcherTabs.value.length);
const lastTab = computed((): SwitchTab => switcherTabs.value[totalTabs.value - 1]);
const chunkSize = computed((): number => Math.max(shownLimit.value - (isLastChunk.value ? 0 : 2), 1));
const isLastChunk = computed(
  (): boolean => props.index === totalTabs.value - 1 || totalTabs.value - shownLimit.value === startAt.value || !isOverSize.value
);
const tabsChunk = computed((): Array<SwitchTab> => switcherTabs.value.slice(startAt.value, startAt.value + chunkSize.value));
const activeShownIndex = computed(() => vIndex.value - startAt.value);
const isOverSize = computed((): boolean => totalTabs.value > shownLimit.value);

const isActiveIsFocused = computed((): boolean => {
  return vIndex.value < startAt.value || vIndex.value < startAt.value + chunkSize.value;
});

const setCenterView = (): void => {
  if (isActiveIsFocused.value) {
    return;
  }

  startAt.value = Math.min(totalTabs.value - shownLimit.value, vIndex.value + Math.floor(shownLimit.value / 2) + chunkSize.value);
};

const isPrevPageEnabled = computed((): boolean => vIndex.value > 0 || (!props.autofocus && startAt.value > 0));

const onPrevPage = (): void => {
  if (props.autofocus) {
    onSelect(Math.max(0, vIndex.value - 1));
  }

  if (!props.autofocus || vIndex.value <= startAt.value) {
    startAt.value = Math.max(0, startAt.value + (vIndex.value - startAt.value - 1));
  }
};

const isNextPageEnabled = computed((): boolean => (totalTabs.value - 1 > vIndex.value && props.autofocus) || !isLastChunk.value);

const onNextPage = (): void => {
  if (props.autofocus) {
    onSelect(Math.min(totalTabs.value - 1, vIndex.value + 1));
  }

  if (!props.autofocus || vIndex.value >= startAt.value + chunkSize.value - 1) {
    startAt.value = Math.min(totalTabs.value - shownLimit.value, startAt.value + chunkSize.value);
  }
};

const onSelect = (index: number, isClicked: boolean = false): void => {
  if (!validate()) {
    return;
  }

  if (isClicked) {
    switcherTabs.value[index]?.onClick?.(index);
  }

  if (switcherTabs.value[index]?.route) {
    routeTo(switcherTabs.value[index].route!, { isOverride: true });
  }

  vIndex.value = index;
};

watch(
  () => switcherTabs.value,
  (newValue, oldValue) => {
    if (!validate() || newValue.length === oldValue?.length) {
      return;
    }

    const findNewIndex = newValue.findIndex((tab) => !oldValue.find((oldTab) => isEqual(oldTab, tab)));

    if (findNewIndex !== -1) {
      onSelect(findNewIndex);
      contentRef.value?.removeAttribute('validate');
    }
  },
  { deep: true }
);

watch(
  () => props.index,
  (index) => {
    if (!validate() || index === undefined || +index === tabIndex.value) {
      return;
    }

    vIndex.value = +index;
  }
);

watch(
  () => [switcherTabs.value, props.autoActiveLast],
  () => {
    if (props.autoActiveLast) {
      nextTick(() => {
        vIndex.value = totalTabs.value - 1;
      });
    }
  },
  { immediate: true }
);

defineExpose({ validate, next: onNextPage, previous: onPrevPage, select: onSelect, index: vIndex, name: tabName });
</script>

<style lang="scss">
$tab-navigation-bar: 2rem;

.tab-switcher {
  display: flex;
  flex-direction: column;

  &--with-content {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  &-header {
    &--active {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 0 0.5rem 0;
      gap: 0.125rem;
    }
  }

  &-navigation {
    display: flex;
    max-height: fit-content;

    &:first-child:not(:has(.tab-switcher-navigation__panel-tab--active)) {
      border-bottom: 1px solid var(--color-primary-300);
    }

    @include min-mobile-layout {
      padding: 0.5rem;
    }

    @include mobile-layout {
      padding: 0.25rem;
    }

    &__back,
    &__next {
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;

      @include rtl(transform, scale(-1));

      &:not(&--disabled) {
        cursor: pointer;
      }
    }

    //.tab-switcher--stepper & {
    //  .tab-switcher-navigation__panel-tab {
    //    padding: 0.25rem 1rem;
    //    color: var(--color-primary-100) !important;
    //    background-color: var(--color-primary-900) !important;
    //    box-shadow: var(--shadow-hard);
    //
    //    @include rtl(
    //      clip-path,
    //      polygon(100% 0%, calc(100% - 0.5rem) 50%, 100% 100%, 0.5rem 100%, 0% 50%, 0.5rem 0%),
    //      polygon(calc(100% - 0.5rem) 0%, 100% 50%, calc(100% - 0.5rem) 100%, 0% 100%, 0.5rem 50%, 0% 0%)
    //    );
    //  }
    //}

    &__panel {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      justify-content: space-between;

      &:not(.tab-switcher--fit &) {
        > :first-child {
          flex-grow: 1;
        }
      }

      &-tab {
        position: relative;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        padding: 0.25rem 0.5rem;
        margin: 0 0.125rem;
        overflow: hidden;

        &:not(.tab-switcher--fit &) {
          flex: 1 1 0;
        }

        &:not(.tab-switcher--flat &):not(.tab-switcher--stepper &) {
          box-shadow: var(--shadow-light);

          @include before {
            opacity: 0.05;
            background-color: currentColor;
            box-sizing: content-box;
            transition: 0.3s;
            border-radius: var(--border-radius-regular);
          }
        }

        * {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: pre;
          max-width: 100%;
        }

        @include after {
          top: initial;
          background-color: currentColor;
          border-radius: 2px;
          opacity: 0;
          height: 2px;
          width: 0;
          left: 50%;
          transform: translateX(-50%);
          transition:
            height 0.3s,
            width 0.3s,
            opacity 0.3s,
            transform 0.3s,
            left 0.3s;
        }

        &--mark {
          cursor: initial;
          font-weight: var(--font-weight-semibold);
        }

        &--active {
          &:before {
            opacity: 0.125;
          }

          @include after {
            top: initial;
            background-color: currentColor;
            border-radius: 2px;
            width: 100%;
            left: 0;
            transform: translateX(0);

            &:not(.tab-switcher--flat &) {
              opacity: 0.05;
              height: 2px;
            }

            .tab-switcher--flat & {
              opacity: 1;
              height: 3px;
            }
          }
        }

        &:hover:not(&--active) {
          &:before {
            opacity: 0.075;
          }
        }
      }

      &-actions {
        display: flex;
        align-items: center;
        position: relative;

        > * {
          height: 100%;
          margin: 0 0.125rem;
        }
      }
    }
  }

  &-content {
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include min-mobile-layout {
      padding: 0.5rem;
    }

    @include max-mobile-layout {
      padding: 0.25rem;
    }

    &--overflow-hidden {
      overflow-y: hidden !important;
    }

    &--splash {
      > *:not(:first-child) {
        display: none;
      }
    }
  }
}
</style>
