import { createApp } from 'vue';
import App from './App.vue';
import './register-service-worker';
import router from './router';
import store from './store';
import '@/styles/app.scss';
import { TinyEmitter } from 'tiny-emitter';
import { vzComponents } from '@/shared/components';
import { vzDirectives } from '@/shared/directives';
import i18n from '@/plugins/i18n';
import { GET_AUTH_SESSION, SESSION } from '@/store/auth/auth.constants';
import LocalStorageService from '@shared/services/local-storage.service';
import { container } from 'tsyringe';
import AuthService from '@/store/auth/auth.service';
import '@/shared/elements';
import { authCheck } from '@/store/auth/helpers/auth-check';
import authLocalStore from '@/store/auth/helpers/auth-local-store';
import SocketClientService from '@shared/services/socket-service/socket-client.service';
import { locale } from '@shared/locale';

export const emitter = new TinyEmitter();

try {
  const authService = container.resolve(AuthService);

  const session = await authService[GET_AUTH_SESSION]();
  authLocalStore.save({ [SESSION]: session || null });
  LocalStorageService.save('language', session?.language || locale.active);

  if (session?._id && session?.key) {
    SocketClientService.setup({ uid: session._id, key: session.key });
  } else {
    SocketClientService.terminate();
  }
} catch (e) {
  console.error(e);
} finally {
  const app = createApp(App).use(store).use(router).use(i18n).use(vzComponents).use(vzDirectives);
  app.config.globalProperties.$auth = authCheck;

  app.mount('#app');
  setTimeout(() => document.getElementById('preloader')?.remove());
}
