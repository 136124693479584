<template>
  <div class="vz-page-wrapper">
    <div v-if="activeItem && showSideNavbar" class="vz-page-wrapper__minimal" role="button" @click="isShown = !isShown">
      <vz-avatar v-if="assignedModulesState?.userId && !isShown" class="ms-4" size="32" :hid="assignedModulesState?.userId" />
      <vz-button v-else class="ms-4 pa-1" type="rounded" :icon-name="!isShown ? 'svg:menu' : 'svg:arrow-up'" icon-size="1.125rem" />

      <div class="vz-page-wrapper__menu-item pa-0">
        <span v-if="activeItem?.label">{{ $t(activeItem.label) }}</span>
      </div>
    </div>

    <div v-if="showSideNavbar" :class="['vz-page-wrapper__menu', { 'vz-page-wrapper__menu--hidden': !isShown }]">
      <manager-assignee-select class="mb-4" />

      <div
        v-for="(item, index) in items"
        :key="index"
        :class="['vz-page-wrapper__menu-item', { 'vz-page-wrapper__menu-item--active': item.name === route.name }]"
        @click="onClick(item)"
      >
        <vz-icon v-if="item.icon" size="1.75rem" :name="item.icon" />

        <span v-if="item.label">{{ $t(item.label) }}</span>
      </div>
    </div>

    <div class="d-flex flex-column relative">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RouteItem } from '@/router/models/route-item';
import { computed, ref } from 'vue';
import { useMenuItems } from '@/router/helpers';
import { useRoute } from 'vue-router';
import { isMobile } from '@shared/helpers';
import { routeTo } from '@shared/composables';
import useAuthStore from '@/views/lobby/modals/login-modal/composables/use-auth-store';
import { ASSIGNED } from '@/store/auth/auth.constants';
import ManagerAssigneeSelect from '@/components/fields/manager-assignee-select.vue';

const route = useRoute();

const { useState } = useAuthStore();
const { [ASSIGNED]: assignedModulesState } = useState([ASSIGNED]);

const isShown = ref<boolean>(!isMobile);

const activeItem = computed(() => items.value.find((item) => item.name === route.name));
const authModules = computed(() =>
  assignedModulesState.value
    ? Object.entries(assignedModulesState.value.modules)
        .filter(([_, value]) => value.length)
        .map(([key]) => key)
    : null
);

const items = useMenuItems({
  routeName: computed(() => route.meta.parentName as string),
  authModules,
});

const showSideNavbar = computed(() => {
  return (items.value?.length && items.value.length > 1) || assignedModulesState.value?.userId;
});

const onClick = (item: RouteItem) => {
  routeTo({ name: item.name });

  isShown.value = false;
};
</script>

<style scoped lang="scss">
.vz-page-wrapper {
  display: flex;
  height: 100%;
  margin-top: 0.5rem;

  @include max-mobile-layout {
    flex-direction: column;
  }

  > :last-child {
    flex-grow: 1;
    overflow: hidden;

    @include min-mobile-layout {
      padding: 0 0.5rem;
    }
  }

  &__minimal {
    background: var(--color-primary-900);
    box-shadow: var(--shadow-hard);
    width: 100%;
    display: flex;
    gap: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    align-items: center;
    font-weight: var(--font-weight-semibold);

    > button {
      margin: 0.25rem;

      @include rtl(transform, scaleX(-1));
    }

    @include min-mobile-layout {
      display: none;
    }
  }

  &__menu {
    @include min-mobile-layout {
      min-width: 360px;
      max-width: 360px;
    }

    @include max-mobile-layout {
      min-width: 100%;

      &--hidden {
        display: none;
      }
    }

    background: var(--color-primary-900);
    box-shadow: var(--shadow-hard);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0.5rem;

    &-item {
      cursor: pointer;
      color: var(--color-primary-100);
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      border-radius: var(--border-radius-light);
      transition: background 0.2s ease-in-out;
      text-transform: uppercase;
      font-weight: var(--font-weight-regular);

      &:hover:not(&--active) {
        background: var(--color-primary-700);
      }

      &--active {
        background: var(--color-primary-100);
        color: var(--color-primary-900);
      }
    }
  }
}
</style>
