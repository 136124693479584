import type { GetLetterHeaderFooterRes, GetUserConfigurationRes, UpdateUserConfigurationReq, UpdatePhoneNumberReq } from '@/views/settings/types';
import { inject, injectable } from 'tsyringe';
import ApiService from '@shared/services/api-service/api.service';
import {
  AGREE_TO_TERMS,
  GET_LETTER_HEADER_FOOTER,
  GET_USER_CONFIGURATION,
  UPDATE_LETTER_HEADER_FOOTER,
  UPDATE_USER_CONFIGURATION,
  UPDATE_PHONE_NUMBER,
} from '@/views/settings/store/settings.constants';
import { BaseResponse } from '@shared/models';

@injectable()
export default class SettingsService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private authUrl = '/auth';
  private baseUrl = '/settings';

  public async [AGREE_TO_TERMS](): Promise<BaseResponse> {
    return await this.apiService.put<BaseResponse>(`${this.baseUrl}/${AGREE_TO_TERMS}`, {});
  }

  public async [GET_USER_CONFIGURATION](): Promise<GetUserConfigurationRes> {
    return await this.apiService.get<GetUserConfigurationRes>(`${this.baseUrl}/${GET_USER_CONFIGURATION}`);
  }

  public async [UPDATE_USER_CONFIGURATION](payload: UpdateUserConfigurationReq): Promise<BaseResponse> {
    return await this.apiService.post<BaseResponse>(`${this.baseUrl}/${UPDATE_USER_CONFIGURATION}`, payload);
  }

  public async [UPDATE_PHONE_NUMBER](payload: UpdatePhoneNumberReq): Promise<BaseResponse> {
    return await this.apiService.post<BaseResponse>(`${this.authUrl}/${UPDATE_PHONE_NUMBER}`, payload);
  }

  public async [GET_LETTER_HEADER_FOOTER](): Promise<GetLetterHeaderFooterRes> {
    return await this.apiService.get<GetLetterHeaderFooterRes>(`${this.baseUrl}/${GET_LETTER_HEADER_FOOTER}`);
  }

  public async [UPDATE_LETTER_HEADER_FOOTER](letter: FormData): Promise<BaseResponse> {
    return await this.apiService.post<BaseResponse>(`${this.baseUrl}/${UPDATE_LETTER_HEADER_FOOTER}`, letter);
  }
}
