import type { RouteRecordRaw } from 'vue-router';
import MessengerRoutesMap from '@/views/messenger/constants/messenger-routes-map';

const Messenger = () => import(/* webpackChunkName: "messenger" */ '@/views/messenger/messenger.view.vue');

const messengerRoutes: Readonly<RouteRecordRaw> = {
  path: `${MessengerRoutesMap.PATHS[MessengerRoutesMap.MESSENGER]}/:chatId?`,
  name: MessengerRoutesMap.MESSENGER,
  component: Messenger,
  meta: {
    unprotected: true,
    signed: true,
    icon: 'svg:message',
  },
};

export default messengerRoutes;
