import type { RouteLocation } from 'vue-router';
import router from '@/router';
import { isEqual } from 'lodash';

export const routeTo = async (
  route: Partial<Pick<RouteLocation, 'name' | 'path' | 'params' | 'query'>> | string,
  config?: Partial<{ isOverride: boolean; useCache: boolean }>
): Promise<void> => {
  const { isOverride = false, useCache = true } = config || {};
  const { name = undefined, path = undefined, query = undefined, params = undefined } = typeof route === 'string' ? { name: route } : route;
  const { name: currentName, path: currentPath, query: currentQuery, params: currentParams } = router.currentRoute.value;

  if ((currentName !== name && currentPath !== path) || !isEqual(currentQuery, query) || !isEqual(currentParams, params)) {
    const routeParams = isOverride ? { ...(currentParams || []), ...(params || []) } : { ...(params || []) };
    const routeQuery = isOverride ? { ...(currentQuery || []), ...(query || []) } : { ...(query || []) };

    const routeName = name || currentName;

    const route = {
      ...(path ? { path } : { name: routeName }),
      query: routeQuery,
      params: routeParams,
    } as RouteLocation;

    await (useCache ? router.push(route) : router.replace(route));
  }
};
