import { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';
import jobOfferRoutes from 'src/views/job/views/offer';
import jobManagerRoutes from 'src/views/job/views/manager';

const jobRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.JOBS],
  name: Modules.JOBS,
  redirect: { name: Modules.FREELANCE.JOB_OFFER },
  children: [jobOfferRoutes, jobManagerRoutes],
};

export default jobRoutes;
