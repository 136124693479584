import type { Module } from 'vuex';
import type { RootState } from '@/store';
import type { GeneralState } from '@/store/client/client.types';
import type { GetExchangeRatesRes } from '@/store/client/models';
import clientActions from './client.actions';
import clientMutations from './client.mutations';
import clientGetters from './client.getters';
import { ADDRESSES_CACHE, COMPANIES_CACHE, EXCHANGE_RATES } from '@/store/client/client.constants';

export const initialState = (): GeneralState => ({
  [EXCHANGE_RATES]: {} as GetExchangeRatesRes,
  [ADDRESSES_CACHE]: {},
  [COMPANIES_CACHE]: {},
});

const generalStore: Module<GeneralState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: clientMutations,
  actions: clientActions,
  getters: clientGetters,
};

export default generalStore;
