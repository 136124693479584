import type { ListCompaniesRes } from 'src/views/employee/types';
import type { BaseAddress, BaseOptionsResponse } from '@/shared/models';
import type { GetVideoDetailsRes, GetExchangeRatesRes, GetExchangeRateRes, GetAttachmentRes, CreatePdfReq } from '@/store/client/models';
import {
  CREATE_PDF,
  GET_ATTACHMENT,
  GET_EXCHANGE_RATE,
  GET_EXCHANGE_RATES,
  GET_VIDEO_DETAILS,
  LIST_ADDRESSES,
  LIST_COMPANIES,
} from '@/store/client/client.constants';
import ApiService from '@/shared/services/api-service/api.service';
import { inject, injectable } from 'tsyringe';

const GET_ATTACHMENT_TIMEOUT = 3 * 60 * 1000; // 3 minutes
const CREATE_PDF_TIMEOUT = 3 * 60 * 1000; // 3 minutes

@injectable()
export default class ClientServices {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private currencyUrl = '/currency';
  private locationUrl = '/location';
  private employeeUrl = '/employee';
  private mediaUrl = '/media';
  private attachmentUrl = '/attachments';

  public async [GET_EXCHANGE_RATE](fromCurrency: string, amount: number, toCurrency?: string): Promise<GetExchangeRateRes> {
    return this.apiService.post<GetExchangeRateRes>(`${this.currencyUrl}/${GET_EXCHANGE_RATE}`, { fromCurrency, toCurrency, amount });
  }

  public async [GET_EXCHANGE_RATES](): Promise<GetExchangeRatesRes> {
    return this.apiService.get<GetExchangeRatesRes>(`${this.currencyUrl}/${GET_EXCHANGE_RATES}`);
  }

  public async [LIST_COMPANIES](query: string): Promise<ListCompaniesRes> {
    return this.apiService.get<ListCompaniesRes>(`${this.employeeUrl}/${LIST_COMPANIES}?search=${query}`);
  }

  public async [LIST_ADDRESSES](query: string): Promise<BaseOptionsResponse<BaseAddress>> {
    return this.apiService.get<BaseOptionsResponse<BaseAddress>>(`${this.locationUrl}/${LIST_ADDRESSES}?search=${query}`, { allowOnceAtTime: true });
  }

  public async [GET_VIDEO_DETAILS](url: string): Promise<GetVideoDetailsRes> {
    return this.apiService.post<GetVideoDetailsRes>(`${this.mediaUrl}/${GET_VIDEO_DETAILS}`, { url });
  }

  public async [GET_ATTACHMENT](id: string, noCache?: boolean): Promise<GetAttachmentRes> {
    return this.apiService.get<GetAttachmentRes>(`${this.attachmentUrl}/${GET_ATTACHMENT}/${id}`, { timeout: GET_ATTACHMENT_TIMEOUT, noCache });
  }

  public async [CREATE_PDF](data: CreatePdfReq): Promise<string> {
    return this.apiService.post<string>(`${this.attachmentUrl}/${CREATE_PDF}`, data, { timeout: CREATE_PDF_TIMEOUT });
  }
}
