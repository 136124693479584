import en from './en.json';
import enShared from './en-shared.json';
import he from './he.json';
import heShared from './he-shared.json';
import { get } from 'lodash';
import languages from '@json/language.json';
import i18n from '@/plugins/i18n';
import { AvailableLanguages } from '@/plugins/i18n/available-languages.enum';
import LocalStorageService from '@shared/services/local-storage.service';

const defaultDictionary = { ...en, ...enShared };

export type Language = keyof typeof AvailableLanguages;
export type DictionarySchema = typeof defaultDictionary;
export type LanguageJson = typeof languages;

const DEFAULT_LANGUAGE: Language = process.env.VUE_APP_DEFAULT_LANGUAGE as Language;

const LANGUAGE_REGEX: Record<Partial<Language> | string, RegExp> = {
  ru: /[\u0400-\u04FF]/,
  ar: /[\u0600-\u06FF]/,
  he: /[\u0590-\u05FF]/,
};

const RTL = ['ar', 'fa', 'he'];

const translations: Record<Language, DictionarySchema> = { en: defaultDictionary, he: { ...he, ...heShared } };

const translate = (
  value: string,
  fields: Record<string, string | number | undefined> = {},
  language: Language = i18n.global.locale,
  isEmpty: boolean = false
): string => {
  const dictionary = translations[language || DEFAULT_LANGUAGE];
  const translateValue = get(dictionary, value);

  if (!translateValue) {
    return isEmpty ? '' : value;
  }

  return (translateValue as string).replace(/(<.*?>)/g, (_, key) => fields[('' + key).slice(1, -1)] || key);
};

const detect = (input: string): Language => {
  const [language] = Object.entries(LANGUAGE_REGEX).find(([_, regex]) => regex.test(input)) || [];

  return language && Object.keys(translations).includes(language) ? (language as Language) : DEFAULT_LANGUAGE;
};

export const direction = (languageCode?: string): string => (languageCode && RTL.includes(languageCode) ? 'rtl' : 'ltr');

const language = (languageCode: string) => {
  const languageJson = languages.find(({ value }) => value === languageCode) as { title: { [key: string]: string }; value: string } | undefined;

  return languageJson?.title[i18n.global.locale] || languageJson?.title[languageCode];
};

const active = (() => {
  const localLanguage = LocalStorageService.load('language') as any;
  const navigatorLanguage = (window.navigator.languages?.[0] || window.navigator.language).slice(0, 2) as any;
  const enabledLanguages = Object.values(AvailableLanguages);

  if (enabledLanguages.includes(localLanguage)) {
    return localLanguage as keyof typeof AvailableLanguages;
  }

  if (enabledLanguages.includes(navigatorLanguage)) {
    return navigatorLanguage as keyof typeof AvailableLanguages;
  }

  return DEFAULT_LANGUAGE;
})();

export const locale = { translate, detect, direction, language, active, RTL, DEFAULT_LANGUAGE };
