import type { ActionTree } from 'vuex';
import type { GeneralState } from '@/store/client/client.types';
import type { CreatePdfReq, GetAttachmentRes, GetVideoDetailsRes } from '@/store/client/models';
import type { BaseAddress, BaseOptions } from '@/shared/models';
import type { RootState } from '@/store';
import type { ListCompaniesRes } from 'src/views/employee/types';
import {
  ADDRESSES_CACHE,
  COMPANIES_CACHE,
  CREATE_PDF,
  EXCHANGE_RATES,
  GET_ATTACHMENT,
  GET_EXCHANGE_RATES,
  GET_VIDEO_DETAILS,
  LIST_ADDRESSES,
  LIST_COMPANIES,
  SET_ADDRESSES_CACHE,
  SET_COMPANIES_CACHE,
  SET_EXCHANGE_RATES,
} from '@/store/client/client.constants';
import { container } from 'tsyringe';
import ClientServices from '@/store/client/service/client.services';
import StoreCache from '@/store/services/store.cache';
import { StoreNamespace } from '@/store/store-namespace';

const generalServices = container.resolve(ClientServices);

const clientActions: ActionTree<GeneralState, RootState> = {
  [GET_EXCHANGE_RATES]: async ({ commit, state }): Promise<void> => {
    const today = new Date().toDateString();
    const createdAt = new Date(state[EXCHANGE_RATES].createdAt || 0).toDateString();

    if (today !== createdAt) {
      if (!state[EXCHANGE_RATES].createdAt) {
        state[EXCHANGE_RATES].createdAt = Date.now();
      }

      try {
        const res = await generalServices[GET_EXCHANGE_RATES]();

        commit(SET_EXCHANGE_RATES, res);
      } catch (e) {
        console.error(e);
      }
    }
  },

  [LIST_COMPANIES]: async (_, query: string): Promise<ListCompaniesRes['companies']> => {
    if ((query || []).length < 3) {
      return [];
    }

    const cacheService = new StoreCache(COMPANIES_CACHE, SET_COMPANIES_CACHE, StoreNamespace.GENERAL_MODULE);
    const cache = cacheService.get<string, ListCompaniesRes['companies']>(query);

    if (cache) {
      return cache;
    }

    const { companies } = await generalServices[LIST_COMPANIES](query);
    cacheService.set<{ query: string }, ListCompaniesRes['companies']>({ query }, companies, { expiredIn: 3 * 60 * 1000 });

    return companies;
  },

  [LIST_ADDRESSES]: async (_, query: string): Promise<BaseOptions<BaseAddress>> => {
    if ((query || []).length < 3) {
      return [];
    }

    const cacheService = new StoreCache(ADDRESSES_CACHE, SET_ADDRESSES_CACHE, StoreNamespace.GENERAL_MODULE);
    const cache = cacheService.get<string, BaseOptions<BaseAddress>>(query);

    if (cache) {
      return cache;
    }

    const { options } = await generalServices[LIST_ADDRESSES](query);
    cacheService.set<{ query: string }, BaseOptions<BaseAddress>>({ query }, options, { expiredIn: 3 * 60 * 1000 });

    return options;
  },

  [GET_VIDEO_DETAILS]: async (_, videoUrl: string): Promise<GetVideoDetailsRes> => {
    return await generalServices[GET_VIDEO_DETAILS](videoUrl);
  },

  [GET_ATTACHMENT]: async (_, { id, noCache }: { id: string; noCache?: boolean }): Promise<GetAttachmentRes> => {
    return await generalServices[GET_ATTACHMENT](id, noCache);
  },

  [CREATE_PDF]: async (_, data: CreatePdfReq): Promise<string> => {
    return await generalServices[CREATE_PDF](data);
  },
};

export default clientActions;
