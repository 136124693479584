import { isRtl } from '@/plugins/i18n/helpers';
import dayjs from 'dayjs';
import i18n from '@/plugins/i18n';
import LocalStorageService from '@shared/services/local-storage.service';
import { AvailableLanguages } from '@/plugins/i18n/available-languages.enum';

export const setLanguage = (languageCode: string): void => {
  dayjs.locale(languageCode);
  i18n.global.locale = languageCode as keyof typeof AvailableLanguages;
  document.documentElement.setAttribute('lang', languageCode);
  document.documentElement.setAttribute('dir', isRtl(languageCode) ? 'rtl' : 'ltr');
  LocalStorageService.save('language', languageCode);
};
