<template>
  <div
    v-if="employee"
    class="employee-strip"
    :class="{ clickable: clickable, active: active, skeleton: loading || getEmployeeRequest.loading.value }"
    @click="$emit('select', employee)"
  >
    <vz-avatar :hid="hid || employee._id" :size="avatarSize" :verified="employee.verified" />

    <div :class="['d-flex', { 'flex-column': horizontal, 'gap-2': !horizontal }]">
      <div class="employee-strip__full-name">
        <p class="text-ellipsis">{{ employee.firstName }}</p>
        <p class="text-ellipsis">{{ employee.lastName }}</p>
      </div>

      <p v-if="showCompany" class="text-subtitle-2">{{ employee.company }}</p>
    </div>
  </div>

  <div v-else-if="group" class="employee-strip" :class="{ clickable: clickable, active: active, skeleton: loading }" @click="$emit('select', group)">
    <vz-avatar v-for="(gHid, index) in groupHid" :key="index" :hid="gHid" :size="avatarSize" />

    <div class="employee-strip__group">
      <p class="d-flex gap-1">
        <span class="text-ellipsis">{{ group.title }}</span>
        <span v-if="groupMore">(+{{ groupMore }})</span>
      </p>

      <p v-if="group?.description" class="text-ellipsis text-subtitle-1">{{ group?.description }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProfileBaseDetails, SearchEmployeeItem } from 'src/views/employee/types';
import type { GetConversationRes } from '@/views/job/types';
import { computed, type PropType, watch } from 'vue';
import useEmployeeStore from '@/views/employee/composables/use-employee-store';
import { GET_EMPLOYEE } from '@/views/employee/store/employee.constants';
import { useAsync } from '@shared/composables';

const props = defineProps({
  horizontal: { type: Boolean, default: false },
  clickable: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  item: {
    type: [Object, String] as PropType<SearchEmployeeItem | (SearchEmployeeItem & { conversation?: GetConversationRes }) | undefined>,
    default: undefined,
  },
  active: { type: Boolean, default: false },
  avatarSize: { type: [String, Number], default: 32 },
  showCompany: { type: Boolean, default: false },
  hid: { type: String as PropType<string | undefined>, default: undefined },
});

defineEmits(['select']);

const { [GET_EMPLOYEE]: getEmployeeAction } = useEmployeeStore().useActions([GET_EMPLOYEE]);
const getEmployeeRequest = useAsync<ProfileBaseDetails>(getEmployeeAction as (userId: string) => Promise<ProfileBaseDetails>);

const employee = computed(() => {
  if (getEmployeeRequest.results.value) {
    return getEmployeeRequest.results.value;
  }

  return props.item?.participants ? undefined : props.item;
});

const group = computed(() => (props.item?.participants ? props.item : undefined));
const groupHid = computed(() => props.item?.participants?.slice(0, 3));
const groupMore = computed(() => (props.item?.participants?.length || 0) - (groupHid.value?.length || 0));

watch(
  () => props.hid,
  (hid) => {
    if (hid) {
      getEmployeeRequest.call(hid);
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.employee-strip {
  font-size: var(--font-size-18);
  transition:
    color 0.3s,
    background-color 0.3s;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .vz-avatar:not(:first-child) {
    margin-inline-start: -1.5rem;
  }

  &__group {
    flex-direction: column;
  }

  &__group,
  &__full-name {
    display: flex;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
    gap: 0.25rem;

    > * {
      text-transform: capitalize;
    }
  }

  &.active {
    background-color: var(--color-primary-100);
    border-radius: var(--border-radius-regular);
  }
}
</style>
