import type { ItemFunctionArg, ItemTextProp, ItemTextReturn } from '../models';
import store from '@/store';
import { LANGUAGE } from '@/store/constants';
import LocalStorageService from '@shared/services/local-storage.service';
import { locale } from '@shared/locale';

export const itemTextFunction = (item: ItemFunctionArg, key: string | Array<string> | null): any => {
  if (typeof item === 'object' && key) {
    const keys = Array.isArray(key) ? key : [key];
    const firstKey = Object.keys(item || {}).find((key: string) => keys.includes(key));

    return firstKey ? item[firstKey as keyof typeof item] : item;
  }

  return item;
};

export const useGetItemText = (prop: ItemTextProp, callback: (value: any) => any = (value) => value): ItemTextReturn => {
  return (item: ItemFunctionArg) => {
    const currentLanguage = store.getters[LANGUAGE] || LocalStorageService.load('language');
    const text = prop instanceof Function ? prop(item) : itemTextFunction(item, prop);

    if (text instanceof Object) {
      return text[currentLanguage || locale.DEFAULT_LANGUAGE] || text[locale.DEFAULT_LANGUAGE] || null;
    }

    return text ? callback(text) : null;
  };
};
