<template>
  <promotion v-if="isPromotionScreen" />

  <template v-else>
    <top-navbar v-if="!isSplashScreen" />

    <vz-page-wrapper>
      <router-view />
    </vz-page-wrapper>

    <bottom-navbar v-if="!isSplashScreen" />
  </template>

  <vz-popup />

  <terms-of-service-modal v-if="isLoggedIn && !termOfServiceSignAt" />

  <login-modal v-if="!isLoggedIn && !isSplashScreen" />
</template>
<script setup lang="ts">
import '@shared/services/api-service/helpers/register-api-service';
import '@shared/services/api-service/helpers/add-api-interceptors';
import { computed, defineAsyncComponent, onMounted, watch } from 'vue';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import { useVisualViewport } from '@shared/composables';
import ThemeService from '@shared/services/css-service/theme-service';
import { useRoute } from 'vue-router';
import VzPageWrapper from '@shared/components/vz-page-wrapper.vue';
import VzPopup from '@shared/components/popup/vz-popup.vue';
import useAuthStore from '@/views/lobby/modals/login-modal/composables/use-auth-store';
import { GOOGLE_AUTH_ME, LOAD_LOCAL_AUTH_STATE } from '@/store/auth/auth.constants';
import { ref } from 'vue';
import { OPEN_LOGIN_MODAL } from '@/views/lobby/constants/lobby-events';
import { emitter } from '@/main';

const LoginModal = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/modals/login-modal/login-modal.component.vue'));
const TopNavbar = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/components/top-navbar/app-top-navbar.vue'));
const Promotion = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/promotion/promotion.view.vue'));
const BottomNavbar = defineAsyncComponent(
  () => import(/* webpackChunkName: "main" */ '@/views/lobby/components/bottom-navbar/app-bottom-navbar.vue')
);

const TermsOfServiceModal = defineAsyncComponent(() => import('@/components/terms-of-service-modal.vue'));
const isNewGoogleUser = ref({ isNewGoogleUser: null });

const route = useRoute();

const { isLoggedIn, isImpersonate, termOfServiceSignAt } = useAuthUser();
const { [LOAD_LOCAL_AUTH_STATE]: loadLocalAuthState } = useAuthStore().useMutations([LOAD_LOCAL_AUTH_STATE]);
const { [GOOGLE_AUTH_ME]: getGoogleUserAction } = useAuthStore().useActions([GOOGLE_AUTH_ME]);

const isSplashScreen = computed(() => route.meta.isSplashScreen);
const isPromotionScreen = computed(() => !isLoggedIn.value && !isSplashScreen.value);

onMounted(() => {
  useVisualViewport();
  loadLocalAuthState();
});

onMounted(async () => {
  const data: any = await getGoogleUserAction();
  const user = data && data.user;

  const isGoogleGuestUser = user?.userConfiguration?.isGoogleUser && user?.userConfiguration?.isNewGoogleUser;

  if (isGoogleGuestUser) {
    emitter.emit(OPEN_LOGIN_MODAL, { isSignUp: true, isNewGoogleUser: true, googleUser: user });
    isNewGoogleUser.value = user.userConfiguration.isNewGoogleUser;
  }
});

watch(
  () => isImpersonate.value,
  (newValue) => {
    ThemeService.set(newValue ? { name: 'warning' } : { name: 'default' });
  },
  { immediate: true }
);
</script>
