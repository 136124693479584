import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import type { FirebaseApp } from '@firebase/app';

type Config = Partial<{
  saveTokenCallback: (token: string) => Promise<void> | void;
}>;

export default class FirebaseClientService {
  public app: FirebaseApp;
  public tokenId: string;

  readonly credential: Record<string, any> = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  };

  constructor(private config: Config) {
    this.app = initializeApp(this.credential);
    const isNotificationSupported = 'Notification' in window;

    if (!isNotificationSupported) {
      return;
    }

    setTimeout(async () => {
      if (Notification.permission === 'granted') {
        await this.initFirebase();

        return;
      }

      Notification.requestPermission().then(async (permission): Promise<void> => {
        if (permission !== 'granted') {
          return;
        }

        await this.initFirebase();
      });
    }, 5000);
  }

  private pushHandle(): void {
    navigator.serviceWorker.addEventListener('message', (event: MessageEvent<{ type: string; data: any }>) => {
      if (event.data?.type !== 'pushData') {
        return;
      }

      localStorage.setItem('pushData', JSON.stringify(event.data.data));
    });
  }

  private async getToken(): Promise<void> {
    try {
      const message = getMessaging(this.app);
      this.tokenId = await getToken(message, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY });
      this.config?.saveTokenCallback?.(this.tokenId);

      console.log({ message, tokenId: this.tokenId, config: this.config, app: this.app });

      onMessage(message, () => {
        navigator.serviceWorker
          ?.register('/firebase-messaging-sw.js')
          .catch((error) => console.error('firebaseClientService:register', 'failed', error));
      });
    } catch (e) {
      console.error('firebaseClientService:token', e);
    }
  }

  private async initFirebase(): Promise<void> {
    const isNotificationSupported = 'Notification' in window && (await isSupported());
    const isServiceWorkerSupported = 'serviceWorker' in navigator;

    if (!isNotificationSupported || !isServiceWorkerSupported) {
      console.warn(
        'firebaseClientService:initFirebase',
        `${[!isNotificationSupported ? 'notification' : null, !isServiceWorkerSupported ? 'serviceWorker' : null]
          .filter((value) => value)
          .join(' and ')} is not supported`
      );

      return;
    }

    try {
      await navigator.serviceWorker.ready;
      await this.getToken();
      this.pushHandle();
    } catch (error) {
      console.error('firebaseClientService:initFirebase', error);
    }
  }
}
