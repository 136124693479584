import { type App } from 'vue';
import { vueValidate } from '@/shared/directives/validate';
import { vueVisibility } from '@shared/directives/visibility';

const vzDirectives = {
  install: (app: App<Element>) => {
    app.directive('validate', vueValidate);
    app.directive('visibility', vueVisibility);
  },
};

export { vzDirectives };
