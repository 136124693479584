<template>
  <slot name="header" />

  <div
    ref="splitViewRef"
    :class="[
      'split-view',
      {
        'split-view--auto-scale': autoScale,
        'split-view--resizable': isResizable,
        'split-view--collapsed': isCollapsed,
        'split-view--pinned': isPinned,
        'split-view--resizing': isResizing,
        'split-view--non-overlay': nonOverlay,
        'split-view--invert': invert,
      },
    ]"
  >
    <div class="split-view__panel split-view__panel--main" :style="{ width: !$slots['dynamic'] ? '100%' : mainWidth }" @click="onClick">
      <slot v-if="!invert" />

      <slot v-else name="dynamic" :close="close" :open="open" />
    </div>

    <template v-if="$slots['dynamic']">
      <div ref="splitViewDynamicRef" class="split-view__panel split-view__panel--dynamic" :style="{ width: dynamicWidth }">
        <div
          class="split-view__panel--dynamic-divider"
          v-on="{ ...(isResizable ? { mousedown: startResize } : {}), ...(!hideToggle ? {} : { dblclick: toggleCollapse }) }"
        >
          <div class="split-view__panel--dynamic-buttons">
            <div v-if="!hideToggle" class="split-view__panel--dynamic-buttons-toggle" @mousedown.stop @click.stop="toggleCollapse">
              <vz-icon size="1rem" name="svg:arrow-right" color="mono-700" :aria-label="$t('GENERAL.COLLAPSE')" />
            </div>

            <div v-if="!isCollapsed && !hidePin" class="split-view__panel--dynamic-buttons-pinned" @mousedown.stop @click="isPinned = !isPinned">
              <vz-icon
                size="1rem"
                name="svg:thumbtack"
                color="mono-700"
                :type="isPinned ? 'solid' : 'regular'"
                :aria-label="$t('GENERAL.THUMBTACK')"
              />
            </div>

            <slot name="actions" />
          </div>
        </div>

        <div :class="['split-view__panel--dynamic-content', { 'overflow-x-hidden': isCollapsed }]">
          <slot v-if="!invert" name="dynamic" :close="close" :open="open" />

          <slot v-else />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, onUnmounted, type PropType, ref, watch } from 'vue';
import { isRtl } from '@/plugins/i18n/helpers';

const MIN_SENSITIVE = 1;

const props = defineProps({
  invert: { type: Boolean, default: false },
  nonOverlay: { type: Boolean, default: false },
  autoScale: { type: Boolean, default: false },
  resizable: { type: Boolean, default: true },
  autoShown: { type: Boolean, default: false },
  minWidth: { type: String as PropType<`${number}px` | `${number}rem` | `${number}%`>, default: '30%' },
  maxWidth: { type: String as PropType<`${number}px` | `${number}rem` | `${number}%`>, default: '50%' },
  hideToggle: { type: Boolean, default: false },
  hidePin: { type: Boolean, default: false },
  closeOnEscape: { type: Boolean, default: false },
});

const emit = defineEmits(['open', 'close']);

const convertSizeToPercent = (size: `${number}px` | `${number}rem` | `${number}%`): number => {
  const sizeInt = parseFloat(size);
  const widthPercent = splitViewRef.value?.offsetWidth || 0;

  if (size.endsWith('px')) {
    return (sizeInt / widthPercent) * 100;
  } else if (size.endsWith('rem')) {
    return ((sizeInt * 16) / widthPercent) * 100;
  }

  return sizeInt;
};

const manualMin = computed((): number => convertSizeToPercent(props.minWidth));
const manualMax = computed((): number => convertSizeToPercent(props.maxWidth || props.minWidth));
const minViewWidth = computed(() => Math.max(convertSizeToPercent('96px'), manualMin.value));

const isResizing = ref<boolean>(false);
const isPinned = ref<boolean>(false);
const splitViewRef = ref<HTMLDivElement | null>(null);
const splitViewDynamicRef = ref<HTMLDivElement | null>(null);
const dynamicContentPercent = ref<number>(0);
const lastDynamicContentPercent = ref<number>(0);
const isCollapsed = ref<boolean>(true);
const isResizable = computed(
  () => props.resizable && (isCollapsed.value || convertSizeToPercent(props.minWidth) !== convertSizeToPercent(props.maxWidth))
);

const dynamicWidth = computed((): string => {
  const max = `min(calc(${props.maxWidth} * var(--split-view-ratio)),100%)`;
  const min = `min(calc(${minViewWidth.value}% * var(--split-view-ratio)),100%)`;

  return isCollapsed.value ? `48px` : `max(min(${dynamicContentPercent.value}%,${max}),${min})`;
});

const mainWidth = computed((): string => `calc(100% - ${dynamicWidth.value})`);

const startResize = (event: MouseEvent) => {
  isResizing.value = true;
  document.body.style.cursor = 'ew-resize';
  document.body.style.userSelect = 'none';

  let debounce: ReturnType<typeof setTimeout>;
  const startX = event.clientX;
  const parentWidth = splitViewRef.value?.offsetWidth ?? 0;
  const contentWidth = splitViewDynamicRef.value!.querySelector('.split-view__panel--dynamic-content')!.clientWidth;
  const startPercent = (contentWidth / parentWidth) * 100;

  const onResize = (moveEvent: MouseEvent) => {
    const currentX = moveEvent.clientX;
    const diffX = ((currentX - startX) / parentWidth) * 100;
    const newPercent = isRtl() ? startPercent + diffX : startPercent - diffX;

    const isOverMinSensitive = Math.abs(diffX) > MIN_SENSITIVE;
    const isClosing = !props.hideToggle && !isCollapsed.value && isOverMinSensitive && minViewWidth.value > newPercent * 1.2;
    const isOpening = isCollapsed.value && newPercent > MIN_SENSITIVE;

    if (isClosing || isOpening) {
      onResizeEnd();
      toggleCollapse();

      return;
    }

    dynamicContentPercent.value = newPercent;
    lastDynamicContentPercent.value = dynamicContentPercent.value;

    clearTimeout(debounce);
    debounce = setTimeout(onResizeEnd, 500);
  };

  const onResizeEnd = () => {
    window.removeEventListener('mousemove', onResize);
    window.removeEventListener('mouseup', onResizeEnd);

    document.body.style.cursor = '';
    document.body.style.userSelect = '';
    isResizing.value = false;
  };

  window.addEventListener('mousemove', onResize);
  window.addEventListener('mouseup', onResizeEnd);
};

const onKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    close();
  }
};

const toggleCollapse = () => {
  if (props.hideToggle && !isCollapsed.value) {
    return;
  }

  if (isCollapsed.value) {
    const maxViewWidth = convertSizeToPercent(props.maxWidth);

    dynamicContentPercent.value = lastDynamicContentPercent.value
      ? Math.min(Math.max(lastDynamicContentPercent.value, minViewWidth.value), maxViewWidth)
      : (manualMin.value + manualMax.value) / 2;

    isPinned.value = false;

    if (props.closeOnEscape) {
      window.addEventListener('keydown', onKeydown);
    }
  } else {
    lastDynamicContentPercent.value = dynamicContentPercent.value;
    dynamicContentPercent.value = 0;
    window.removeEventListener('keydown', onKeydown);
  }

  isCollapsed.value = !isCollapsed.value;
  emit(isCollapsed.value ? 'close' : 'open');
};

const close = () => {
  if (!isCollapsed.value) {
    toggleCollapse();
  }
};

const open = () => {
  if (isCollapsed.value) {
    toggleCollapse();
  }
};

const onClick = () => {
  if (props.hidePin) {
    return;
  }

  const mainContent = splitViewRef.value?.querySelector('.split-view__panel--main') as HTMLDivElement;

  if (!mainContent) {
    return;
  }

  const mainComputedStyle = window.getComputedStyle(mainContent);

  if (mainComputedStyle.opacity === '1') {
    return;
  }

  if (!isCollapsed.value && !isPinned.value) {
    toggleCollapse();
  }
};

onUnmounted(() => {
  window.removeEventListener('keydown', onKeydown);
});

watch(
  () => splitViewRef.value,
  () => {
    if (props.autoShown) {
      open();
    }
  }
);

defineExpose({ open, close, isCollapsed, isPinned });
</script>

<style lang="scss" scoped>
.split-view {
  --split-view-background: var(--color-background-active);
  --split-view-divider-color: var(--color-primary-900);
  --split-view-hover-opacity: 0.5;
  --split-view-ratio: 1;

  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  &--auto-scale {
    @include normal-layout {
      --split-view-ratio: 1.5;
    }

    @include tablet-layout {
      --split-view-ratio: 2;
    }

    @include mobile-layout {
      --split-view-ratio: 2.75;
    }
  }

  &--resizing {
    opacity: 0.8;
  }

  &__panel {
    overflow: auto;

    &--main {
      transition: opacity 0.3s;

      @include max-normal-layout {
        &:not(.split-view--non-overlay &):not(.split-view--collapsed &) {
          min-width: calc(100% - 48px);
          opacity: 0.9;
        }
      }
    }

    &--dynamic {
      display: flex;
      z-index: 1;
      height: 100%;
      @include rtl(border-radius, 4px 0 0 4px, 0 4px 4px 0);

      &-content {
        position: relative;
        flex-grow: 1;
        transition:
          opacity 0.2s,
          padding 0.3s ease;
        opacity: 0;

        &:not(.split-view--collapsed &) {
          opacity: 1;
        }

        @include max-normal-layout {
          transition: background-color 0.3s ease;
          margin-inline-start: -14px;

          &:not(.split-view--non-overlay &):not(.split-view--collapsed &) {
            padding: 8px 24px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
            background-color: var(--split-view-background);
          }
        }
      }

      &:not(.split-view--non-overlay &) {
        @include max-normal-layout {
          position: absolute;
          @include inline-end(0);
        }

        @include max-tablet-layout {
          &:not(.split-view--collapsed &) {
            min-width: 100%;
          }
        }
      }

      &-divider {
        position: relative;
        width: 12px;
        user-select: none;
        padding: 0 4px;
        margin: 0 8px;

        .split-view--resizable & {
          cursor: ew-resize;
        }

        &:not(.split-view--non-overlay &) {
          @include max-normal-layout {
            transition: padding 0.3s ease;

            .split-view--collapsed & {
              padding: 0 8px;
            }
          }
        }

        @include before {
          width: 2px;
          background-color: var(--split-view-divider-color);
          opacity: 0.05;
          @include rtl(transform, translateX(calc(-150% - 2px)), translateX(calc(150% + 2px)));

          &:not(.split-view--non-overlay &) {
            @include max-normal-layout {
              .split-view--collapsed & {
                @include rtl(transform, translateX(calc(-150% - 4px)) !important, translateX(calc(150% + 4px)) !important);
              }
            }
          }
        }

        .split-view--resizing & {
          &::before {
            opacity: 1;
          }
        }

        &:hover:not(:has(*:hover))::before {
          .split-view--resizable & {
            opacity: var(--split-view-hover-opacity);
          }
        }
      }

      &-buttons {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: absolute;
        left: 50%;
        z-index: 10;
        transform: translateX(-50%);

        .split-view--collapsed & {
          left: calc(50% - 2px);
        }

        &-pinned,
        &-toggle {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding: 5px;
          aspect-ratio: 1;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background-color: var(--split-view-background);

          @include before() {
            border: 2px solid var(--split-view-divider-color);
            border-radius: 50%;
            opacity: 0.15;
          }

          &:hover:before {
            opacity: var(--split-view-hover-opacity);
          }
        }

        &-pinned {
          display: none;

          &:not(.split-view--non-overlay &) {
            @include max-normal-layout {
              display: flex;
            }
          }
        }

        &-toggle {
          transition: transform 0.3s ease;

          &:not(.split-view--collapsed &) {
            transform: scaleX(-1);
          }
        }
      }
    }
  }
}
</style>
