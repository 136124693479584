import type { ActionTree } from 'vuex';
import type { AuthState } from '@/store/auth/index';
import type {
  AuthEmailValidation,
  AuthForgotPasswordReq,
  AuthRecoverPasswordReq,
  AuthUnsecuredSignIn,
  AuthUnsecuredSignUp,
} from '@/store/auth/auth.type';
import {
  SIGN_IN,
  SET_SESSION,
  GET_AUTH_SESSION,
  SIGN_OUT,
  SIGN_UP,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  EMAIL_VALIDATE,
  VERIFICATION_EXPIRED_AT,
  SET_VERIFICATION_EXPIRED_AT,
  IMPERSONATE_TO,
  SAVE_TOKEN,
  SESSION,
  GOOGLE_AUTHORIZATION,
  GOOGLE_AUTH_ME,
} from '@/store/auth/auth.constants';
import CryptService from '@/shared/services/crypt.service';
import { container } from 'tsyringe';
import AuthService from '@/store/auth/auth.service';
import { type RootState } from '@/store';
import { RESET_STATE, SET_LANGUAGE } from '@/store/constants';
import { useRoot } from '@/store/helpers/use-root';
import { firebaseMessaging } from '@shared/helpers';
import authLocalStore from '@/store/auth/helpers/auth-local-store';
import SocketClientService from '@shared/services/socket-service/socket-client.service';
import LocalStorageService from '@shared/services/local-storage.service';
const root = useRoot();

const authService = container.resolve(AuthService);

const authActions: ActionTree<AuthState, RootState> = {
  [GET_AUTH_SESSION]: async ({ commit, state }): Promise<void> => {
    const { [SET_LANGUAGE]: setLanguage } = useRoot();
    const session = await authService[GET_AUTH_SESSION]();

    setLanguage(session?.language);
    commit(SET_SESSION, session || null);
    authLocalStore.save(state);
    LocalStorageService.save('language', session?.language);

    if (session?._id && session?.key) {
      SocketClientService.setup({ uid: session._id, key: session.key });
    } else {
      SocketClientService.terminate();
    }
  },

  [IMPERSONATE_TO]: async ({ dispatch, state }, userId?: string): Promise<void> => {
    const user = await authService[IMPERSONATE_TO](!state[SESSION] || state[SESSION]?._id !== userId ? userId : undefined);

    if (user) {
      root[RESET_STATE]();

      await dispatch(GET_AUTH_SESSION);
    }
  },

  [SIGN_UP]: async ({ dispatch }, payload: AuthUnsecuredSignUp): Promise<void> => {
    const { email, password, ...restForm } = payload;

    await authService[SIGN_UP]({ hash: CryptService.encrypt(password, email), email, ...restForm });
    await dispatch(GET_AUTH_SESSION);
  },

  [SIGN_IN]: async ({ dispatch }, payload: AuthUnsecuredSignIn): Promise<void> => {
    const { password, email } = payload;

    await authService[SIGN_IN]({ hash: CryptService.encrypt(password, email), email });
    await dispatch(GET_AUTH_SESSION);
  },

  [SIGN_OUT]: async ({ dispatch }): Promise<void> => {
    const tokens = [firebaseMessaging.tokenId].filter((value) => value);

    await authService[SIGN_OUT]({ tokens });
    await dispatch(GET_AUTH_SESSION);
  },

  [FORGOT_PASSWORD]: async (_, payload: AuthForgotPasswordReq): Promise<number> => {
    const { enableIn } = await authService[FORGOT_PASSWORD](payload);

    return enableIn;
  },

  [CHANGE_PASSWORD]: async ({ dispatch }, payload: AuthRecoverPasswordReq): Promise<void> => {
    const { password, email, code } = payload;

    await authService[CHANGE_PASSWORD]({ hash: CryptService.encrypt(password, email), email, code: +code });
    await dispatch(SIGN_IN, { email, password });
  },

  [EMAIL_VALIDATE]: async ({ state, commit }, { forceCall, ...content }: AuthEmailValidation & { forceCall?: boolean }): Promise<number | void> => {
    if (forceCall || !state[VERIFICATION_EXPIRED_AT] || state[VERIFICATION_EXPIRED_AT] < Date.now()) {
      const { expiredAt, enableIn } = await authService[EMAIL_VALIDATE](content);
      commit(SET_VERIFICATION_EXPIRED_AT, expiredAt);

      return enableIn;
    }
  },

  [SAVE_TOKEN]: async (_, token: string): Promise<void> => {
    try {
      await authService[SAVE_TOKEN](token);
    } catch (e) {
      console.error(e);
    }
  },

  [GOOGLE_AUTH_ME]: async ({ dispatch }): Promise<any> => {
    const res = await authService[GOOGLE_AUTH_ME]();
    await dispatch(GET_AUTH_SESSION);
    return res
  },

  [GOOGLE_AUTHORIZATION]: async (_, url: string): Promise<string> => {
    const { _url } = await authService[GOOGLE_AUTHORIZATION](url);

    return _url;
  },
};

export default authActions;
