export default class Modules {
  public static readonly MAINTENANCE: string = 'maintenance';
  public static readonly OFFLINE: string = 'offline';
  public static readonly ERROR: string = 'error';
  public static readonly FORBIDDEN: string = 'forbidden';
  public static readonly HOME: string = 'home';
  public static readonly EMPLOYEE_SEARCH: string = 'search';
  public static readonly NOTIFICATIONS: string = 'notifications';
  public static readonly MESSENGER: string = 'messenger';
  public static readonly JOBS: string = 'job';

  public static readonly MANAGER: Record<string, string> = {
    JOB_MANAGER: 'job-manager',
    JOB_CALENDAR: 'job-calendar',
    JOB_MANAGER_POST: 'job-manager-post',
    JOB_MANAGER_PROJECT: 'job-manager-project',
    JOB_MANAGER_GROUP: 'job-manager-group',
    JOB_MANAGER_ASSIGNEE: 'job-manager-assignee',
    JOB_MANAGER_TEMPLATE: 'job-manager-template',
  };

  public static readonly PERSONAL: Record<string, string> = {
    CALENDAR: 'calendar',
    UPCOMING_EVENT: 'upcoming-events',
  };

  public static readonly FREELANCE: Record<string, string> = {
    JOB_OFFER: 'job-offer',
  };

  public static readonly PATHS: Record<string, string> = {
    [Modules.HOME]: '/',
    [Modules.JOBS]: '/job',
    [Modules.MANAGER.JOB_MANAGER]: '/job/manager',
    [Modules.MANAGER.JOB_CALENDAR]: '/job/manager/calendar',
    [Modules.MANAGER.JOB_MANAGER_POST]: '/job/manager/post',
    [Modules.MANAGER.JOB_MANAGER_PROJECT]: '/job/manager/project',
    [Modules.MANAGER.JOB_MANAGER_GROUP]: '/job/manager/group/:groupId?',
    [Modules.MANAGER.JOB_MANAGER_ASSIGNEE]: '/job/manager/assignee',
    [Modules.MANAGER.JOB_MANAGER_TEMPLATE]: '/job/manager/template',
    [Modules.FREELANCE.JOB_OFFER]: '/job/offer/:positionId?/:conversationId?',
    [Modules.PERSONAL.CALENDAR]: '/calendar',
    [Modules.PERSONAL.UPCOMING_EVENT]: '/calendar/upcoming',
    [Modules.EMPLOYEE_SEARCH]: '/search',
    [Modules.NOTIFICATIONS]: '/notifications',
    [Modules.MESSENGER]: '/messenger',
    [Modules.MAINTENANCE]: '/maintenance',
    [Modules.OFFLINE]: '/offline',
    [Modules.ERROR]: '/error',
    [Modules.FORBIDDEN]: '/forbidden',
  };

  public static readonly COMPONENT: Record<string, () => object> = {
    [Modules.MAINTENANCE]: () => import(/* webpackChunkName: "maintenance" */ '@/views/errors/maintenance.view.vue'),
    [Modules.OFFLINE]: () => import(/* webpackChunkName: "offline" */ '@/views/errors/offline.view.vue'),
    [Modules.ERROR]: () => import(/* webpackChunkName: "error" */ '@/views/errors/error.view.vue'),
    [Modules.FORBIDDEN]: () => import(/* webpackChunkName: "forbidden" */ '@/views/errors/forbidden.view.vue'),
  };
}

export const DEFAULT_PAGE_TITLE = 'Production Book';
