import type { RouteRecordRaw, RouteRecordNormalized } from 'vue-router';
import router from '@/router';

export const findAllChildrenRoutes = (routeName?: string, routes: Array<RouteRecordNormalized> = router.getRoutes()): Array<RouteRecordRaw> => {
  if (!routeName) {
    return routes;
  }

  return routes.find((r: RouteRecordNormalized) => r.name === routeName)?.children || [];
};

export const getChildrenRouteNames = (routeName?: string, routes: Array<RouteRecordNormalized> = router.getRoutes()): Array<string> => {
  return routeName
    ? findAllChildrenRoutes(routeName, routes)
        .filter((route: RouteRecordRaw) => route.name && route.meta?.title)
        .map((route: RouteRecordRaw) => route.name as string)
    : [];
};
