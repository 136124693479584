import type AugmentedActionContext from '@/store/types/augmented-action-context';
import type { UserAssignee } from '@/views/job/types/job-manager-assignee.type';
import type { Module } from 'vuex';
import type { RootState } from '@/store';
import type { ProfileFullDetails } from 'src/views/employee/types';
import type {
  GetAuthSessionRes,
  AuthEmailValidation,
  AuthForgotPasswordReq,
  AuthRecoverPasswordReq,
  AuthSignInReq,
  AuthSignUpReq,
} from '@/store/auth/auth.type';
import {
  SESSION,
  VERIFICATION_EXPIRED_AT,
  SIGN_UP,
  SIGN_IN,
  SIGN_OUT,
  GET_AUTH_SESSION,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  EMAIL_VALIDATE,
  IMPERSONATE_TO,
  SAVE_TOKEN,
  SET_SESSION,
  UPDATE_USER,
  SET_VERIFICATION_EXPIRED_AT,
  GET_SESSION,
  GET_AUTH_MODULES,
  GET_AUTH_KEY,
  GET_CURRENCY,
  IS_LOGGED_IN,
  IS_IMPERSONATED,
  ASSIGNED,
  SET_ASSIGNEE,
  GET_ASSIGNEE_LIST,
  GET_ASSIGNED_USER_ID,
  LOAD_LOCAL_AUTH_STATE,
  GET_ASSIGNED_MODULES,
  GOOGLE_AUTHORIZATION,
  GOOGLE_AUTH_ME,
} from '@/store/auth/auth.constants';
import authActions from './auth.actions';
import authMutations from './auth.mutations';
import authGetters from './auth.getters';
import authLocalStore from '@/store/auth/helpers/auth-local-store';

type StoreContext = AugmentedActionContext<AuthState, AuthMutations, AuthActions>;

// store
export type AuthState = {
  [SESSION]: GetAuthSessionRes | null;
  [ASSIGNED]: UserAssignee | null;
  [VERIFICATION_EXPIRED_AT]: number | null;
};

export interface AuthActions {
  [SIGN_UP]({ dispatch }: StoreContext, payload: AuthSignUpReq): Promise<void>;
  [SIGN_IN]({ state, commit }: StoreContext, payload: AuthSignInReq): Promise<void>;
  [SIGN_OUT]({ state, commit }: StoreContext): Promise<void>;
  [GET_AUTH_SESSION]({ state, commit, dispatch }: StoreContext): Promise<void>;
  [FORGOT_PASSWORD](_: StoreContext, payload: AuthForgotPasswordReq): Promise<number>;
  [CHANGE_PASSWORD](_: StoreContext, payload: AuthRecoverPasswordReq): Promise<void>;
  [EMAIL_VALIDATE]({ state, commit }: StoreContext, payload: AuthEmailValidation & { forceCall?: boolean }): Promise<number | void>;
  [IMPERSONATE_TO]({ commit, state }: StoreContext, userId?: string): Promise<void>;
  [SAVE_TOKEN](_: StoreContext, token: string): Promise<void>;
  [GOOGLE_AUTHORIZATION](_: StoreContext, url: string): Promise<string>;
  [GOOGLE_AUTH_ME](_: StoreContext): Promise<void>;
}

export interface AuthMutations<S = AuthState> {
  [LOAD_LOCAL_AUTH_STATE](state: S): void;
  [SET_SESSION](state: S, payload?: GetAuthSessionRes): void;
  [UPDATE_USER](state: S, update: Partial<ProfileFullDetails>): void;
  [SET_VERIFICATION_EXPIRED_AT](state: S, expiredAt?: number): void;
  [SET_ASSIGNEE](state: S, payload?: UserAssignee): void;
}

export type AuthGetters<S = AuthState> = {
  [GET_AUTH_KEY](state: S): string;
  [GET_AUTH_MODULES](state: S): Array<string>;
  [GET_SESSION](state: S): GetAuthSessionRes | undefined;
  [GET_CURRENCY](state: S): string | undefined;
  [IS_LOGGED_IN](state: S): boolean;
  [IS_IMPERSONATED](state: S): boolean;
  [GET_ASSIGNEE_LIST](state: S): Array<UserAssignee> | null;
  [GET_ASSIGNED_USER_ID](state: S): string | undefined;
  [GET_ASSIGNED_MODULES](state: S): UserAssignee['modules'];
};

export const initialState = (): AuthState => {
  const state = authLocalStore.load();

  return {
    [SESSION]: null,
    [VERIFICATION_EXPIRED_AT]: null,
    [ASSIGNED]: null,
    ...(state || {}),
  };
};

const authStore: Module<AuthState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: authMutations,
  actions: authActions,
  getters: authGetters,
};

export default authStore;
