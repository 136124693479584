import Modules from '@/router/constants/modules';
import type { RouteRecordRaw } from 'vue-router';

const Employee = () => import(/* webpackChunkName: "employee-search" */ '@/views/employee/employee.view.vue');

const employeeRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.EMPLOYEE_SEARCH],
  name: Modules.EMPLOYEE_SEARCH,
  component: Employee,
};

export default employeeRoutes;
