import type { GetAuthSessionRes } from '@/store/auth/auth.type';
import type { GetterTree } from 'vuex';
import type { RootState } from '@/store';
import type { AuthState } from '@/store/auth/index';
import type { UserAssignee } from '@/views/job/types/job-manager-assignee.type';
import {
  GET_AUTH_MODULES,
  GET_AUTH_KEY,
  GET_SESSION,
  IS_IMPERSONATED,
  IS_LOGGED_IN,
  SESSION,
  GET_CURRENCY,
  GET_ASSIGNEE_LIST,
  ASSIGNED,
  GET_ASSIGNED_USER_ID,
  GET_ASSIGNED_MODULES,
} from '@/store/auth/auth.constants';

const authGetters: GetterTree<AuthState, RootState> = {
  [GET_AUTH_KEY](state): string | undefined {
    return state[SESSION]?._id;
  },

  [GET_AUTH_MODULES](state): Array<string> {
    return state[SESSION]?.modules || [];
  },

  [GET_SESSION](state): GetAuthSessionRes | null {
    return state[SESSION] || null;
  },

  [GET_CURRENCY](state): string | undefined {
    return state[SESSION]?.currency || state[SESSION]?.wage?.currencyType;
  },

  [IS_LOGGED_IN](state): boolean {
    return !!state[SESSION]?._id;
  },

  [IS_IMPERSONATED](state): boolean {
    return !!state[SESSION]?.isImpersonate;
  },

  [GET_ASSIGNEE_LIST](state): Array<UserAssignee> | null {
    return state[SESSION]?.assigned?.length ? state[SESSION].assigned : null;
  },

  [GET_ASSIGNED_USER_ID](state): string | undefined {
    return state[ASSIGNED]?.userId;
  },

  [GET_ASSIGNED_MODULES](state): UserAssignee['modules'] {
    return state[ASSIGNED]?.modules || {};
  },
};

export default authGetters;
