// actions
export const LIST_COMPANIES = 'list-companies';
export const LIST_ADDRESSES = 'list-addresses';
export const GET_VIDEO_DETAILS = 'get-video-details';
export const GET_EXCHANGE_RATE = 'get-exchange-rate';
export const GET_EXCHANGE_RATES = 'get-exchange-rates';
export const GET_ATTACHMENT = 'get-attachment';
export const CREATE_PDF = 'create-pdf';

// state
export const COMPANIES_CACHE = 'companies';
export const ADDRESSES_CACHE = 'addresses-cache';
export const EXCHANGE_RATES = 'exchange-rates';

// mutation
export const SET_COMPANIES_CACHE = 'set-companies';
export const SET_ADDRESSES_CACHE = 'set-addresses';
export const SET_EXCHANGE_RATES = 'set-exchange-rates';
