<template>
  <div :class="['vz-avatar', { 'vz-avatar--edit': isEditMode }]">
    <div
      :class="['vz-avatar__image-container', { 'vz-avatar__image-container--editable': editable }]"
      :style="{ width: adjSize + 'px', height: adjSize + 'px' }"
    >
      <image-cropper
        v-if="isEditMode"
        auto-zoom
        class="fill-width"
        export-type="base64"
        :ratio="1"
        :size="cropSize"
        :quality="0.7"
        :src="vModel || hidSrc"
        :default-src="defaultSrc"
        :preview-height="adjSize"
        :preview-width="adjSize"
        :stencil-height="adjSize"
        :stencil-width="adjSize"
        v-bind="$attrs"
        @save="onSave"
        @cancel="isEditMode = false"
      ></image-cropper>

      <div v-else-if="isLoading" class="vz-avatar__loading">
        <img class="vz-avatar__image" role="presentation" :src="imgSrc" :style="avatarStyle" alt="" />

        <span :style="spinnerStyle" />
      </div>

      <template v-else>
        <img
          class="vz-avatar__image"
          role="presentation"
          :src="imgSrc"
          :style="{ ...avatarStyle, display: isLoading ? 'none' : 'initial' }"
          :alt="title || hidAlt || $t('GENERAL.AVATAR')"
          :title="title || hidAlt"
          v-bind="{ ...(clickable ? { role: 'button' } : {}) }"
          @error="errorLoading = true"
          @load="localLoading = false"
        />

        <slot />

        <vz-icon
          v-if="editable"
          clickable
          class="vz-avatar__edit-icon"
          name="svg:edit"
          size="50%"
          :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.EDIT' })"
          v-on="{ click: externalEditor ? $emit('edit') : () => (isEditMode = true) }"
        />

        <vz-icon v-else-if="verified && !isLoading" name="svg:verified" class="vz-avatar__verified" :size="iconSize" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SizeUnit } from '@shared/types';
import type { ColorName } from '@shared/services/css-service/types';
import { computed, type PropType, ref, watch } from 'vue';
import ImageCropper from '@/shared/components/vz-image-cropper.vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import useEmployeeStore from '@/views/employee/composables/use-employee-store';
import { GET_AVATAR } from '@/views/employee/store/employee.constants';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';

const t = useTranslator();

const props = defineProps({
  externalEditor: { type: Boolean, default: false },
  modelValue: { type: String as PropType<string | undefined | null>, default: undefined },
  size: { type: [String, Number], default: 32 },
  verified: { type: Boolean, default: false },
  clickable: { type: Boolean, default: false },
  editable: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  title: { type: String as PropType<string | undefined>, default: undefined },
  hid: { type: String as PropType<string | undefined>, default: undefined },
  color: { type: String as PropType<ColorName | undefined>, default: undefined },
  cropSize: { type: Number, default: 192 },
});

const emit = defineEmits(['update:model-value', 'edit']);

const vModel = computed({
  get: (): string | null | undefined => props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const { useActions } = useEmployeeStore();
const { [GET_AVATAR]: getAvatarAction } = useActions([GET_AVATAR]);

const errorLoading = ref<boolean>(false);
const localLoading = ref<boolean>(false);
const isEditMode = ref<boolean>(false);

const hidSrc = ref<string | undefined>('');
const hidAlt = ref<string | undefined>(undefined);
const imgSrc = computed((): string => (!errorLoading.value && (hidSrc.value || vModel.value)) || defaultSrc.value);
const defaultSrc = computed(() => require('@/assets/images/avatar.svg'));

const adjSize = computed(() => (isEditMode.value ? Math.min(props.cropSize, +props.size * 2) : +props.size));

const iconSize = computed((): SizeUnit => `${adjSize.value / 2.5}px`);

const avatarStyle = computed(() => ({
  width: adjSize.value - 1 + 'px',
  height: adjSize.value - 1 + 'px',
  borderWidth: Math.ceil((adjSize.value / 32) * 1.1) + 'px',
  ...(props.color ? { borderColor: getThemeColor(props.color) } : {}),
}));
const spinnerStyle = computed(() => ({ height: props.size + 'px', width: props.size + 'px', borderWidth: Math.ceil(adjSize.value / 24) + 'px' }));
const isLoading = computed(() => props.loading || localLoading.value);

const onSave = ({ data }: { data: string | null }) => {
  emit('update:model-value', data || null);
  hidSrc.value = data || '';
  isEditMode.value = false;
};

watch(
  () => props.hid,
  async () => {
    if (!props.hid) {
      return;
    }

    try {
      localLoading.value = true;
      const { image, alt } = await getAvatarAction([props.hid]);

      hidSrc.value = image;
      hidAlt.value = alt;
    } catch (e) {
      hidSrc.value = defaultSrc.value;
    } finally {
      localLoading.value = false;
    }
  },
  { immediate: true }
);
</script>

<style lang="scss">
.vz-avatar {
  position: relative;
  border-radius: 50%;

  &--edit {
    outline: var(--outline-focus);
  }

  &__image {
    position: relative;
    object-fit: cover;
    border-radius: 50%;
    border-style: solid;
    border-color: var(--color-background-regular);
    background-color: var(--color-primary-300);
    transition: filter 0.3s;
  }

  &__loading {
    position: relative;

    span {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      animation: rotation 1s linear infinite;
      border-style: solid;
      border-bottom-color: var(--color-primary-700);
      z-index: 1;
      height: 100%;
      width: 100%;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__verified {
    position: absolute !important;
    bottom: 0;
    right: -4%;
  }

  &__edit-icon {
    color: var(--color-mono-100);
    padding: 8px;
    border-radius: 36%;
    opacity: 0 !important;
    transition: opacity 0.3s;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__image-container {
    position: relative;

    &--editable {
      &:not(.vz-avatar--edit &) {
        &:hover {
          img {
            filter: contrast(0.2);
          }

          .vz-avatar__edit-icon {
            opacity: 0.85 !important;
          }
        }
      }
    }
  }

  .vz-image-cropper__preview {
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
